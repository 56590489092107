import React from 'react';
import Menu from '../components/Menu';
import MenuSection from '../components/Menu/MenuSection';
import MenuItem from '../components/Menu/MenuItem';
import LocationButton from '../components/LocationButton';
import Layout from '../layouts/layout';

const MenuVistaMain = () => (
	<Layout title="Menu - Vista Downtown">
		<div>
			<h1>Menu - Downtown Vista</h1>

			<p>Active military personnel enjoy an extra 10% off</p>

			<LocationButton
				className="pink-button inline-button"
				href="http://toasttab.com/when-pigs-fly-bbq-main-street"
			>
				Order Online For Pickup at Downtown Vista
			</LocationButton>

			<Menu>
				<MenuSection title="Smoked Meats">
					<MenuItem title="Pulled Pork" />
					<MenuItem title="Pulled Chicken" />
					<MenuItem title="1/4 Chicken (dark meat)" />
					<MenuItem title="Signature Homemade Sausage (+$1.50)" />
					<MenuItem title="Beef Tri-tip (+$2)" />
					<MenuItem title="Beef Brisket (+$2)" />
					<MenuItem title="Beef Ribs (+$3)" />
					<MenuItem title="Pork Spare Ribs (+$3)" />
				</MenuSection>

				<MenuSection title="Housemade Sides">
					<MenuItem title="Smoked Corn" />
					<MenuItem title="Mac & Cheese" />
					<MenuItem title="Garlic Mashed Potatoes" />
					<MenuItem title="BBQ Beans" />
					<MenuItem title="Southern Coleslaw" />
					<MenuItem title="Macaroni Salad" />
					<MenuItem title="Potato Salad" />
					<MenuItem title="Pasta Salad" />
					<MenuItem title="Housemade Chips" />
					<MenuItem title="Three Bean Salad" />
					<MenuItem title="Hand-cut fries" />
				</MenuSection>

				<MenuSection title="The Good Stuff">
					<MenuItem
						title="The Shack"
						subtitle="(1/2 lb of meat)"
						description="1 meat, 2 sides, and a hawaiian roll or corn muffin"
						price="13"
					/>
					<MenuItem
						title="The Shed"
						subtitle="(1 lb of meat)"
						description="2 meats, 2 sides, and a hawaiian roll or corn muffin"
						price="15"
					/>
					<MenuItem
						title="The Barnyard"
						subtitle="(1 1/2 lbs of meat)"
						description="3 meats, 2 sides, and a hawaiian roll or corn muffin"
						price="17"
					/>
					<MenuItem
						title="The Boneyard"
						description={
							<>
								<p>
									slab of pork or beef ribs, 2 sides, + a
									hawaiian roll or housemade corn muffin
									<br />
									<center>
										$24 (half slab)
										<br />
										$32 (full slab)
									</center>
								</p>
							</>
						}
					/>
				</MenuSection>

				<MenuSection
					title="Burgers & Dogs"
					description={
						<>
							Served with hand-cut, seasoned dipstick fries
							<br />
							Make it an Impossible Burger +$2.50
						</>
					}
				>
					<MenuItem
						title="Big Block Burger"
						description="1/2lb smoked Brandt Beef cheeseburger, potato bun, with all the fixin's"
						price="12"
					/>
					<MenuItem
						title="Hubcap Burger"
						description="1/2lb smoked Brandt Beef burger, potato bun, a crispy cheese wheel, and all the fixin's"
						price="15"
					/>
					<MenuItem
						title="Toolbox Burger"
						description="1/2lb smoked Brandt Beef burger, potato bun, pulled pork, sweet pickles, housemade onion rings, BBQ sauce"
						price="15"
					/>
					<MenuItem
						title="Brisket Bleu"
						description="1/2lb smoked Brandt Beef burger, potato bun, smoked brisket, housemade blue cheese dressing"
						price="15"
					/>
					<MenuItem
						title="Small Block Burger"
						description="1/4lb smoked Brandt Beef cheeseburger with all the fixin's"
						price="8"
					/>
					<MenuItem
						title="Hot Rod"
						description="premium Brandt Beef hot dog, spicy mayo, dijon mustard, sweet relish"
						price="7"
					/>
					<MenuItem
						title="Outlaw Hot Rod"
						description="premium Brandt Beef hot dog, outlaw brisket chili, sour cream, green onion"
						price="9"
					/>
					<MenuItem
						title="Dipsticks"
						description="hand-cut, seasoned dipstick fries"
						price="5"
					/>
				</MenuSection>

				<MenuSection title="à la carte">
					<MenuItem title="BBQ sandwich" price="8" />
					<MenuItem title="Shrimp Po' Boy" price="11" />
					<MenuItem title="Hog dog" price="11" />
					<MenuItem title="half slab ribs" price="19" />
					<MenuItem title="full slab ribs" price="28" />
					<MenuItem title="small side" price="3" />
					<MenuItem title="large side" price="8" />
					<MenuItem title="four side combo" price="10" />
				</MenuSection>

				<MenuSection
					title="Lunch specials"
					description={
						<>
							Monday - Friday from 11am-2pm
							<br />
							Some meats extra: tri-tip or brisket +$1, ribs +$3
						</>
					}
				>
					<MenuItem
						title="1 meat plate + drink"
						description="includes a side dish, hawaiian roll or housemade cornbread muffin, and a soda or sweet tea"
						price="8.95"
					/>
					<MenuItem
						title="BBQ sandwich + drink"
						description="includes a side dish and a soda or sweet tea"
						price="8.95"
					/>
					<MenuItem
						title="Loaded salad + drink"
						description="includes a hawaiian roll or housemade cornbread muffin, and a soda or sweet tea. add a choice of meat +$3"
						price="8.95"
					/>
				</MenuSection>

				<MenuSection title="Family Packs">
					<MenuItem
						title="2-4 people"
						description={
							<ul>
								<li>1 lb meat</li>
								<li>2 large sides</li>
								<li>4 hawaiian rolls or buns</li>
								<li>peach cobbler</li>
							</ul>
						}
						price="38"
					/>
					<MenuItem
						title="4-6 people"
						description={
							<ul>
								<li>2 lbs meat</li>
								<li>3 large sides</li>
								<li>6 hawaiian rolls or buns</li>
								<li>peach cobbler</li>
							</ul>
						}
						price="68"
					/>
					<MenuItem
						title="6-8 people"
						description={
							<ul>
								<li>3 lbs meat</li>
								<li>3 large sides</li>
								<li>8 hawaiian rolls or buns</li>
								<li>peach cobbler</li>
							</ul>
						}
						price="85"
					/>
				</MenuSection>

				<MenuSection
					title="Friday & Saturday Specials"
					description="Served from 5pm 'til it's gone"
				>
					<MenuItem title="Ribeye Plate" price="34" />
					<MenuItem title="Prime Rib Plate" price="36" />
				</MenuSection>

				<MenuSection
					title="Happy Hour"
					description="Served all day, every day!"
				>
					<MenuItem
						title="Wings"
						description="Served with housemade bbq sauce"
						price="6"
					/>
					<MenuItem
						title="Riblets"
						description="Juicy, tender pork rib tips"
						price="6"
					/>
					<MenuItem
						title="Pig Candy"
						description="Smoked bacon, need we say more?"
						price="6"
					/>
					<MenuItem
						title="BBQ Nachos"
						description="Housemade chips with pulled pork + all the fixin's"
						price="8"
					/>
					<MenuItem
						title="Deviled Eggs"
						description="Topped with pig candy"
						price="6"
					/>
					<MenuItem
						title="Redneck Tacos"
						description="3 tacos with pulled pork + crunchy coleslaw"
						price="8"
					/>
					<MenuItem
						title="Brisket outlaw chili"
						description="Spicy chili + housemade corn muffin"
						price="8"
					/>
					<MenuItem
						title="Onion rings"
						description="We bet you can't eat just one"
						price="8"
					/>
				</MenuSection>

				<MenuSection
					title="More Stuff"
					description="Includes 2 sides + hawaiian roll or corn muffin"
				>
					<MenuItem title="BBQ Sandwich Plate" price="14" />
					<MenuItem
						title="Shrimp Po' Boy Plate"
						description="Served When Pigs Fly style"
						price="15"
					/>
					<MenuItem title="Hog Dog Plate" price="16" />
				</MenuSection>

				<MenuSection
					title="Kids"
					description="Includes applesauce or side dish + juice box"
				>
					<MenuItem title="BBQ Sandwich" price="7" />
					<MenuItem title="Mac & Cheese" price="6" />
				</MenuSection>

				<MenuSection
					title="Salads"
					description="Add a meat of your choice +$3"
				>
					<MenuItem title="Caesar Salad" price="10" />
					<MenuItem
						title="Loaded Green Salad"
						description="tomato, red onion, smoked corn, broccoli, artichoke hearts, black olives, and hard boiled egg, served on a bed of fresh romaine"
						price="12"
					/>
				</MenuSection>

				<MenuSection title="Drinks">
					<MenuItem title="Soda" price="3" />
					<MenuItem title="Sweet Tea" price="3" />
					<MenuItem title="Coffee" price="3" />
					<MenuItem title="Beer on tap" price="5+" />
				</MenuSection>

				<MenuSection title="Desserts">
					<MenuItem
						title="Smoked Peach Cobbler"
						description="with vanilla bean ice cream +$2"
						price="7"
					/>
					<MenuItem
						title="Berry Cobbler"
						description="Served with vanilla bean ice cream"
						price="9"
					/>
					<MenuItem
						title="Brownie Ice Cream Sandwich"
						description="Vanilla bean ice cream sandwiched between two brownies, and drizzled in chocolate sauce"
						price="9"
					/>
					<MenuItem
						title="Caramel Bread Pudding"
						description="Served with vanilla bean ice cream"
						price="9"
					/>
					<MenuItem title="Freshly-baked Cookies" price="4" />
				</MenuSection>
			</Menu>
		</div>
	</Layout>
);

export default MenuVistaMain;
